$('.post').addClass("invisible").viewportChecker({
    classToAdd: 'visible animated fadeIn',
    classToRemove: 'invisible',
    offset: 100
});
$('.post-up').addClass("invisible").viewportChecker({
    classToAdd: 'visible animated fadeInUp',
    classToRemove: 'invisible',
    offset: 100
});
$('.post-left').addClass("invisible").viewportChecker({
    classToAdd: 'visible animated fadeInLeft',
    classToRemove: 'invisible',
    offset: 100
});
$('.post-right').addClass("invisible").viewportChecker({
    classToAdd: 'visible animated fadeInRight',
    classToRemove: 'invisible',
    offset: 100
});
$('.post-down').addClass("invisible").viewportChecker({
    classToAdd: 'visible animated fadeInDown',
    classToRemove: 'invisible',
    offset: 100
});
$('.post-zoom').addClass("invisible").viewportChecker({
    classToAdd: 'visible animated zoomIn',
    classToRemove: 'invisible',
    offset: 100
});

$('.decorator-left, .decorator-right, .animate-border').viewportChecker({
    classToAdd: 'anmt',
    offset: 100
});

$('.home .icons').viewportChecker({
    offset: 100,
    callbackFunction: function(){
        animateSvg();
    },
});

function animateSvg() {
    const anime = require('./anime');

    anime({
        targets: '.animate-svg *',
        strokeDashoffset: [anime.setDashoffset, 0],
        easing: 'easeInOutSine',
        duration: 3500,
        // direction: 'alternate',
        loop: false
    });
}